import "./ContactForm.scss";
import { InferProps } from "prop-types";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackbars from "../../context/Snackbar/Consumer";
import { contactUs } from '../../services/contactUs';
import routes from "../../utils/routes";
import { getSnackbarArgs } from "../../utils/utils";
import { debounce } from "../../utils/utils";
import DottedLoaderBox from "../../components/DottedLoaderBox/DottedLoaderBox";
import { form, field, PersistedData } from "../../types/contactUs"
import { getFormComponent, getFilteredFieldsOnShowConditions, evaluateSubmissionAllowed, buildFormSubmissionData, evaluateFilesField, isFieldsIdentical } from "../../utils/dynamicForm"
import BottomSheetSelection from "../../components/BottomSheetSelection/BottomSheetSelection";
import FilePicker from "../../components/FilePicker/FilePicker";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import { cardDetails, cardTransactionDetails, updateCardStatus } from "../../services/cards";
import { cardTransactionDetailsReqType, updateCardStatusReqType } from "../../types/cards";
import { cardDisputePostSubmissionBottomSheet, snackBarMessages } from "../../configs/contact-us";
import { AxiosError } from "axios";
import { sendScribeEvent } from "../../utils/scribeHelper";
import i18n from '../../i18n/config';
import { getConfig, getIssueTypeOptions, getUserEmail, isClicked } from "../../utils/contactForm";
import SubmissionSuccessBottomSheet from "../../components/SubmissionSuccessBottomSheet/SubmissionSuccessBottomSheet";

const ContactFormPropTypes = {}

const cardTransactionDispute = "GX Card transaction dispute"
const postSubmissionActionBottomSheet = "post-submission-action-bottom-sheet"

const ContactForm = (props: InferProps<typeof ContactFormPropTypes>) => {
  const { state } = useLocation()
  let { persistedData } = (state || {}) as {
    persistedData?: PersistedData,
  }
  const [issueType, setIssueType] = useState(persistedData?.issueType ?? window.history.state?.usr?.["issueType"] ?? "");
  const [issueTypeOptions, setIssueTypeOptions] = useState<string[]>([])
  const [userEmail, setUserEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [files, setFiles] = useState<File[]>(persistedData?.files ?? window.history.state?.usr?.["files"] ?? [])
  const [formConfig, setFormConfig] = useState<form>({} as form)
  const [formFieldsToDisplay, setFormFieldsToDisplay] = useState<field[]>(persistedData?.formFieldsToDisplay ?? [])
  const [openCardDisputeBottomSheet, setOpenCardDisputeBottomSheet] = useState(false)
  const [openSubmissionBottomSheet, setOpenSubmissionBottomSheet] = useState(false)
  const cardId = useRef<string>("")

  const setStateFromHistory = () => {
    if (window.history.state?.usr?.["issueType"]) setIssueType(window.history.state.usr["issueType"])
    if (window.history.state?.usr?.["files"]?.length) setFiles(window.history.state.usr["files"])
  }

  const setStateInHistory = (state: object) => {
    window.history.replaceState({
      usr: {
        ...(window.history.state?.usr || {}),
        ...state,
      },
    }, document.title)
  }

  const updateStateInHistory = useCallback(
    debounce((issueType: string, files: any[], formConfig: form) => {
      setStateInHistory({ issueType, files, formConfig })
    }),
    []
  )

  const formChangeFunction = (e: any) => {
    const target = e.target
    for (let field of formFieldsToDisplay) {
      if (field.id === target.id) {
        if (field.id === "disputeField") {
          const existingItem = field.value && field.value.find((item: any) => item.id === target.name);
          if (existingItem) {
            existingItem.value = target.value;
          } else {
            field.value = [...(field.value || []), { id: target.name, value: target.value }];
          }
        } else {
          field.value = target.value || ''
          // required field validation
          if (field.required && !target.value) {
            field.error = true
            field.helperText = `${i18n.t("generic_file_empty_msg")}`
            break
          }
          field.error = false
          field.helperText = undefined
        }
        break
      }
    }

    setFormFieldsToDisplay(getFilteredFieldsOnShowConditions(formConfig.fields))
  }

  const setTransactionID = (transactionID: string, formConfig: any) => {
    let field = formConfig.fields.find((field: field) => field.id === "transactionID")
    if (field)
      field.value = transactionID
  }

  useEffect(() => {//mount
    (async () => {
      setLoading(true)
      const getUserEmailResponse = await getUserEmail()
      if (getUserEmailResponse.error)
        openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-user-email-error" }))
      else
        setUserEmail(getUserEmailResponse.res)

      const getIssueTypesResponse = await getIssueTypeOptions()
      if (getIssueTypesResponse.error)
        openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-issue-type-options" }))
      else
        setIssueTypeOptions(getIssueTypesResponse.res)

      setLoading(false)
      const transactionId = window.history.state?.usr?.transactionIDStateData
      if (transactionId && await verifyIfCardTransaction(transactionId)) setIssueType(cardTransactionDispute) // TODO: how to derive this from BE response
      else setStateFromHistory()
    })()
    // cleanups persisted data after component mounted
    const persistedDataCleanup = setTimeout(() => {
      persistedData = undefined
    }, 1000);
    return () => clearTimeout(persistedDataCleanup)
  }, [])


  // fetch the form config for the selected issueType
  useEffect(() => {
    if (!issueType)
      return
    if (window.history.state?.usr?.["loadFieldsFromHistory"]) {
      setFormConfig(window.history.state.usr["formConfig"])

      setStateInHistory({ loadFieldsFromHistory: false })
      return
    }

    (async () => {
      setLoading(true)
      const getFormConfigResponse = await getConfig(issueType)

      if (getFormConfigResponse.error)
        openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-form-config" }))
      else {
        setFormConfig(getFormConfigResponse.res)
        if (window.history.state?.usr?.["transactionIDStateData"]) {
          setTransactionID(window.history.state.usr["transactionIDStateData"], getFormConfigResponse.res)

          // Remove the transactionID from history state once it is pre-filled
          setStateInHistory({ transactionIDStateData: "" })
        }
      }

      setLoading(false)
    })()
  }, [issueType])

  useEffect(() => {
    if (!persistedData && !isFieldsIdentical(formFieldsToDisplay, formConfig.fields)) {
      setFormFieldsToDisplay(getFilteredFieldsOnShowConditions(formConfig.fields))
    }
  }, [formConfig])

  useEffect(() => {
    if (issueType) updateStateInHistory(issueType, files, formConfig)
  }, [issueType, files, formFieldsToDisplay])

  const verifyIfCardTransaction = async (txnID: string) => {
    const body: cardTransactionDetailsReqType = {
      transactionID: txnID
    }
    try {
      const info = await cardTransactionDetails(body)
      if (info) return true
    } catch (error) {
      console.error("Error in verifying txn id", error)
      return false
    }
  }

  const navigate = useNavigate();
  const { openSnackbar, closeSnackbar } = useSnackbars();

  const isInputValid = () => {
    const isFilesRequired = evaluateFilesField(formConfig.documentUploadConfig?.requiredConditions, formFieldsToDisplay)
    if (isFilesRequired) {
      if (files.length <= 0) return false
    }
    return issueType && evaluateSubmissionAllowed(formFieldsToDisplay)
  }

  const onDocumentView = () => {
    setStateInHistory({ loadFieldsFromHistory: true })
  }

  const submitForm = async (e: any) => {
    sendScribeEvent("help_email_submit")
    e.preventDefault()
    if (!isInputValid())
      return
    setSubmitting(true)

    if (issueType === "Card transaction dispute") {
      await handleCardDisputeSubmittion(formConfig)
    } else {
      await handleCommonFormSubmittion()
    }
    setSubmitting(false)

    for (let field of formConfig.fields) {
      if (field.id === "disputeField") {
        field.value = [...(field.value || [])];
      } else {
        field.value = ''
      }
    }

    // exitContactForm()
  }

  const handleCardDisputeSubmittion = async (formConfig: form,) => {
    const transactionID = formConfig.fields.filter(field => field.id === "transactionID")[0].value
    try {
      // check txn ID
      await cardTransactionDetails({ transactionID: transactionID })
      // submit form
      const data = buildFormSubmissionData(files, issueType, formFieldsToDisplay)
      await contactUs(data)
    } catch (error) {
      console.error(error, "error from submittion")
      let snackBarArgs: any = {
        onClose: closeSnackbar,
        uniqueKey: "submit-contact-us-form-error"
      }
      if ((error as AxiosError).response?.data) {
        snackBarArgs = {
          ...snackBarArgs,
          secondaryMessage: ((((error as AxiosError).response?.data) as any).message as string)
        }
      }
      openSnackbar(getSnackbarArgs(snackBarArgs))
      return
    }
    try {
      // get card status
      const getCardStatus = await cardDetails({ transactionID: transactionID })
      const cardStatus = getCardStatus.cardDetails?.status
      const cardID = getCardStatus.cardDetails?.cardID
      // console.log("card status", getCardStatus, getCardStatus.cardDetails?.status, cardStatus === "ACTIVE", cardID, "cardID");
      if (cardStatus === "ACTIVE" && cardID) {
        cardId.current = cardID
        setOpenCardDisputeBottomSheet(true)
        return
      }
      if (getCardStatus.contains(AxiosError)) {
        const snackbarObj = snackBarMessages.get("cardStatusFetchFail")
        exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key)
        return
      }
    } catch (error) {
      const snackbarObj = snackBarMessages.get("cardStatusFetchFail")
      exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key)
    }
    const snackbarObj = snackBarMessages.get("cardDisputeSubmitSuccess")
    exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key)

  }

  const handleCommonFormSubmittion = async () => {
    const data = buildFormSubmissionData(files, issueType, formFieldsToDisplay)
    try {
      await contactUs(data)
      setOpenSubmissionBottomSheet(true)
    } catch (error) {
      console.error(error)
      navigate(routes.contactUsFailed.path, {
        state: {
          persistedData: {
            files,
            issueType,
            formFieldsToDisplay
          },
        }
      })
      return
    }
  }

  const exitContactForm = (message: string = "Your issue has been submitted. We'll drop you an email soon!", variant: string = "primary", key: string = "submit-contact-us-form-success") => {
    openSnackbar(getSnackbarArgs({
      onClose: closeSnackbar,
      uniqueKey: key,
      secondaryMessage: message,
      variant
    }))

    navigate(routes.home.path) // Redirect to home
  }

  const closeCardDisputeBottomSheet = (event: any, id: string) => {
    // If the user has clicked anywhere inside the bottomsheet, don't close it
    if (isClicked(id, event.target))
      return

    setOpenCardDisputeBottomSheet(false)
    exitContactForm()
  }


  const handleCardStatus = async (action: string) => {
    setSubmitting(true)
    if (!action) {
      const snackbarObj = snackBarMessages.get("cardDisputeSubmitSuccess")
      exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key);
      return;
    }

    if (action === "freezeCard") {
      setSubmitting(true);
      try {
        console.log("handleCardStatus cardId" + cardId.current);
        const res = await updateCardFreezeStatus(cardId.current);
        if (res.contains(AxiosError)) {
          const snackbarObj = snackBarMessages.get("cardFreezeFail")
          exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key)
          return
        }
      } catch (error) {
        let errorMessage = ((((error as AxiosError).response?.data) as any)?.message as string)
        if (!errorMessage) {
          const snackbarObj = snackBarMessages.get("cardFreezeFail")
          exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key)
          return
        }
        console.log("errorMessage handleCardStatus:" + errorMessage)
        exitContactForm(errorMessage, "error", "handle-card-freeze-status")
      } finally {
        setSubmitting(false);
      }

    }
    const snackbarObj = snackBarMessages.get("cardFreezeSuccess")
    exitContactForm(snackbarObj?.message, snackbarObj?.variant, snackbarObj?.key);
  };


  const updateCardFreezeStatus = async (cardId: string) => {
    let body: updateCardStatusReqType;
    body = {
      status: "LOCKED",
      cardID: cardId,
    };

    try {
      const res = await updateCardStatus(body);
      return res
    } catch (error) {
      console.error(error);
      return error
    }
  };

  return (
    <>
      <Box component={"form"} onSubmit={submitForm} display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Typography display="block" variant="heading-03" mb={3}>{i18n.t("helpcentre_contact_us_greetings")}</Typography>
          <Typography display="block" variant="body-02" color="text-primary.main" mb={8}>
            {i18n.t("helpcentre_contact_us_body")}
          </Typography>
          <BottomSheetSelection
            label={i18n.t("helpcentre_contact_us_type_enquiry_title")}
            listTitle={i18n.t("helpcentre_contact_us_type_enquiry")}
            value={issueType}
            onChange={(value: string) => { setIssueType(value)}}
            options={issueTypeOptions}
          />
          {
            formFieldsToDisplay.map((field: field) => {
              return (
                <Box key={`${issueType}-${field.id}`} style={{ color: "white" }}>
                  {getFormComponent(field.type, field, formChangeFunction)}
                </Box>
              )
            })
          }

          {
            formConfig.documentUploadConfig?.maxDocuments &&
            <FilePicker
              title={`${i18n.t("generic_upload_document")}` + (evaluateFilesField(formConfig.documentUploadConfig?.requiredConditions, formFieldsToDisplay) ? "" : ` ${i18n.t("generic_optional")}`)}
              {...formConfig.documentUploadConfig}
              onDocumentView={onDocumentView}
              files={files}
              setFiles={setFiles}
            />
          }
        </Box>
        <Button
          variant="contained"
          disabled={!isInputValid() || submitting}
          onClick={submitForm}
          type="submit"
          sx={{
            backgroundColor: "brand-primary.main",
            '&:hover': {
              backgroundColor: 'brand-primary.main'
            },
            '&.Mui-disabled': {
              backgroundColor: 'bg-disabled.main',
            },
            mt: 5
          }}
        >
          <Typography variant="callout-02" p={5} sx={{ color: !isInputValid() || submitting ? 'text-disabled.main' : 'text-primary.main' }}>
            {i18n.t("generic_submit")}
          </Typography>
        </Button>
      </Box>


      <BottomSheet
        open={openCardDisputeBottomSheet}
        onClose={(event: any) => closeCardDisputeBottomSheet(event, postSubmissionActionBottomSheet)}>
        <Box p={6} sx={{ backgroundColor: "bg-secondary.main" }} id={postSubmissionActionBottomSheet}>
          <Box px={3}>
            <Box sx={{ textAlign: 'center', mb: 6 }}><img src={cardDisputePostSubmissionBottomSheet.image} alt="" /></Box>
            <Typography component="h3" variant="heading-04">{cardDisputePostSubmissionBottomSheet.heading}</Typography>
            <Typography component="p" variant="body-02" sx={{ mt: 3, mb: 7 }}>{cardDisputePostSubmissionBottomSheet.subHeading}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 5 }}>
            {
              cardDisputePostSubmissionBottomSheet.actions.map((action: any) => {
                return (
                  <Button
                    variant={action.variant}
                    color={action.color}
                    onClick={() => handleCardStatus(action.action)}
                    key={action.text}
                    sx={{ flexGrow: 1 }}
                  >
                    <Typography variant="callout-02" p={5}>
                      {action.text}
                    </Typography>
                  </Button>
                )
              })
            }
          </Box>
        </Box>
      </BottomSheet>

      <SubmissionSuccessBottomSheet
        openBottomSheet={openSubmissionBottomSheet}
        setOpenBottomSheet={setOpenSubmissionBottomSheet}
        userEmail={userEmail}
      />
      
      {
        submitting || loading ?
          <Box
            sx={{
              zIndex: "3000",
            }}
          >
            <Box
              sx={{
                opacity: "0.75",
                height: "100vh",
                width: "100%",
                position: "fixed",
                top: "0",
                left: "0",
                background: "black"
              }}>
            </Box>
            <DottedLoaderBox
              sx={{
                position: "fixed",
                m: 'auto',
                top: "50%",
                left: '50%',
                transform: "translate(-50%, -50%)"
              }}
            />
          </Box> :
          <></>
      }
    </>
  );
};

ContactForm.propTypes = ContactFormPropTypes;

export default ContactForm;

