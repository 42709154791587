import "./Chatpage.scss";
import Chatbubble, { ChatBubbleProps } from "../../components/Chatbubble/Chatbubble";
import { Box } from "@mui/system";
import ChatInput from "../../components/ChatInput/ChatInput";
import sendIcon from '../../assets/send.svg'
import ChevronDown from '../../assets/chevron-down-black.svg'
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import ChatDivider from "../../components/ChatDivider/ChatDivider";
import FileProgress from "../../components/FileProgress/FileProgress";
import DottedLoader from "../../components/DottedLoader/DottedLoader";
import { closeChatbotCase, getMessages, getOngoingChat, handoverToAgent, sendMessage } from "../../services/chat";
import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { TIME_ZONE, useInterval } from "../../utils/utils";
import useSnackbars from "../../context/Snackbar/Consumer";
import { getSnackbarArgs } from "../../utils/utils";
import CrossSmall from '../../assets/cross-small.svg';
import theme from "../../theme";
import CurvedListItemContainer from "../../components/CurvedListItemContainer/CurvedListItemContainer";
import ListItem from "../../components/ListItem/ListItem";
import { Badge, Typography } from "@mui/material";
import ChevronRight from "../../assets/chevron-right.svg";
import { animateChatOptionsToMessage, animateMessageToChatOptions } from "../../utils/animationTest";
import featureFlags from "../../configs/features";
import { checkPermissions } from "../../utils/mobileComms";
import { chatDocumentUploadConfig } from '../../configs/chat-doc-upload'
import { checkDocumentType, getAllDoctypesToString } from "../../utils/filePicker";
import { useDocUpload } from "../../hooks/useDocUpload";
import { fileData } from "../../types/fileUpload";
import { readURL } from "../../utils/dynamicForm";
import { useNavigate } from "react-router-dom";
import routes from "../../utils/routes";
import { getDoc } from "../../utils/documentUpoad";
import { ChatMessage, SendChatMessage, OnGoingChat, ChatbotState, ChatbotAction } from "../../types/chat";
import { TFeatureFlags } from "../../types/featureFlag";
import { sendScribeEvent } from "../../utils/scribeHelper";
import i18n from '../../i18n/config';
import ChatBubbleLoader from "../../components/ChatBubbleLoader/ChatBubbleLoader";
import { useBlockNavigation } from "../../hooks/useBlockNavigation";
import ActionSheet from "../../components/ActionSheet/ActionSheet";
import Hazard from '../../assets/hazard.svg'
import { ChatbotActionKind, chatIssueTypesWithChatbot, chatIssueTypesWithoutChatbot, chatPageSize, dividerMessageTypes, FeedbackState, MessageType, POLLING_INTERVAL } from "../../constants/chat";
import DottedLoaderBox from "../../components/DottedLoaderBox/DottedLoaderBox";
import { getUserDetails, UserDetails } from "../../services/getUserDetails";
import { getFeatureFlags } from "../../services/featureFlag";
import { DOMAIN_BUSINESS } from "../../constants/domain";

const ChatPage = () => {
  let chatData: any

  const [chatMessages, setChatMessages] = useState([] as ChatMessage[])
  const [currMessage, setCurrMessage] = useState("")
  const [initPageLoading, setInitPageLoading] = useState(true)
  const [oldMessagesLoading, setOldMessagesLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)
  const prevFirstMessageID = useRef("")
  const loadLock = useRef(true)
  const [initialMessagesLoaded, setInitialMessagesLoaded] = useState(false)
  const [showScrollIcon, setShowScrollIcon] = useState(false)
  const unReadMessageDividerPresent = useRef(false)
  const [isChatActive, setIsChatActive] = useState(true);
  const [chatOptionsState, setChatOptionsState] = useState("")
  const chatOptionsRef = useRef<HTMLDivElement>(null)
  const [fileStatus, setUploadFile, fileUplodInfo] = useDocUpload()
  const [file, setFile] = useState({} as fileData)
  const navigator = useNavigate()
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const [ongoingChatId, setOngoingChatId] = useState<null | string>(null)
  const [chatbotState, setChatbotState] = useState<ChatbotState>({ 
    isChatbotResponseLoading: false,
    isChatbotChat: false,
    shouldHandoverToAgent: false,
    isAgentHandoverLoading: false,
    feedbackSubmittedMessages: new Set(),
  });
  const { showPrompt, navigation } = useBlockNavigation(chatbotState.isChatbotChat && !!ongoingChatId)
  const [leaveChatpageLoading, setLeaveChatpageLoading] = useState(false)
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>()
  const [backendFeatureFlags, setBackendFeatureFlags] = useState<TFeatureFlags>({} as TFeatureFlags);
  const chatIssueTypes = useMemo(() => {
    if (userDetails?.chatbotEnabled) {
      return userDetails?.activeProfileDomain == DOMAIN_BUSINESS ? chatIssueTypesWithChatbot['business'] : chatIssueTypesWithChatbot['retail']
    }
    return userDetails?.activeProfileDomain == DOMAIN_BUSINESS ? chatIssueTypesWithoutChatbot['business'] : chatIssueTypesWithoutChatbot['retail']
  }, [userDetails?.activeProfileDomain, userDetails?.chatbotEnabled])

  const dispatchChatbotAction = useCallback((action: ChatbotAction) => {
    const { type, payload } = action
    switch (type) {
      case (ChatbotActionKind.SET_IS_CHATBOT_RESPONSE_LOADING):
        setChatbotState(prevState => ({
          ...prevState,
          isChatbotResponseLoading: payload
        }))
        return
      case (ChatbotActionKind.SET_IS_CHATBOT_CHAT):
        setChatbotState(prevState => ({
          ...prevState,
          isChatbotChat: payload
        }))
        return
      case (ChatbotActionKind.SET_SHOULD_HANDOVER_TO_AGENT):
        setChatbotState(prevState => ({
          ...prevState,
          shouldHandoverToAgent: payload
        }))
        return
      case (ChatbotActionKind.SET_IS_AGENT_HANDOVER_LOADING):
        setChatbotState(prevState => ({
          ...prevState,
          isAgentHandoverLoading: payload
        }))
        return
      case (ChatbotActionKind.ADD_SUBMITTED_FEEDBACK_MESSAGES):
        setChatbotState(prevState => ({
          ...prevState,
          feedbackSubmittedMessages: prevState.feedbackSubmittedMessages.add(payload)
        }))
        return
      default:
        return
    }
  }, [setChatbotState])

  console.log(isChatActive, "isChatActive for this render")

  const { openSnackbar, closeSnackbar } = useSnackbars()

  let getMessagesController = new AbortController()

  const firstMessageObserver = useRef<any>()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const messagesEndObserver = useRef<any>()

  /**
   * This is a helper function to fetch messages based on data passed
   * and sets hasMore to false if there are no more messages
   */
  const getChatMessages = useCallback(async (data: object = {}) => {
    data = {
      ...data,
      "pageSize": chatPageSize
    }
    try {
      const res = await getMessages(data)
      if (!res.pagination.nextCursorId)
        setHasMore(false)
      else
        setHasMore(true) 
      return res
    } catch (error) {
      console.error(error)
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-chat-messages" }))
    }
  }, [])

  /**
   * This function loads the old chat messages (messages before the first message currently on the page)
   */
  const loadOldChatMessages = useCallback(async () => {
    const data = {
      nextCursorId: chatMessages[0].nextCursorId,
      pageSize: 10,
    }

    const res = await getChatMessages(data)
    if (res.messages.length > 0) {
      res.messages[0].nextCursorId = res.pagination.nextCursorId
      setChatMessages((prevState: any) => {
        console.log("updating chat message to load old messages...")
        return [...res.messages, ...prevState]
      })
    }
  }, [chatMessages, getChatMessages])

  const firstMessageRef = useCallback((node: HTMLElement) => {
    if (oldMessagesLoading || !hasMore || !initialMessagesLoaded) return

    if (firstMessageObserver.current)
      firstMessageObserver.current.disconnect()

    firstMessageObserver.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        if (loadLock.current) {
          return
        }
        console.log("first message visible")
        setOldMessagesLoading(true)
        console.log("assigning prevFirstMessageID.current, node.id, node", prevFirstMessageID.current, node.id, node);
        prevFirstMessageID.current = node.id || ""
        await loadOldChatMessages()
        setOldMessagesLoading(false)
      }
    }, {
      threshold: 1,
      rootMargin: "-22px"
    })

    if (node) {
      firstMessageObserver.current.observe(node)
    }

  }, [initialMessagesLoaded, hasMore, loadOldChatMessages, oldMessagesLoading])

  useEffect(() => {
    // Set the value for scroll-padding-top variable
    const headerHeight = document.getElementById('header')?.offsetHeight;
    document.documentElement.style.setProperty('--scroll-padding-top', headerHeight + "px");
    // Load initial chat messages on mount
    (async () => {
      setInitPageLoading(true)
      const getFeatureFlagsResponse = await getFeatureFlags();
      setBackendFeatureFlags(getFeatureFlagsResponse.res)
      const newUserDetails = await getUserDetails()
      setUserDetails(newUserDetails)
      const onGoingChatResponse: OnGoingChat = await getOngoingChat();
      setOngoingChatId(onGoingChatResponse?.chatId || null)
      await loadInitialChatMessages(newUserDetails?.userName);
      setInitPageLoading(false)
      loadLock.current = false
    })()

    // Set observer for end of messages
    if (messagesEndObserver.current)
      messagesEndObserver.current.disconnect()

    messagesEndObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        console.log("messages end visible")
        setHasUnreadMessages(false)
        setUnreadCount(0)
      }
    }, { rootMargin: "-16px" })

    messagesEndObserver.current.observe(messagesEndRef.current)
  }, [])

  useEffect(() => {
    if (backendFeatureFlags?.featureFlags?.chat && !backendFeatureFlags.featureFlags.chat.enabled) {
     navigator(routes.chatNotAvailable.path) 
    }
  }, [backendFeatureFlags])

  // Scroll user to the bottom of the page once initial messages are loaded
  useEffect(() => {
    if (!initPageLoading) {
      if (unReadMessageDividerPresent.current) {
        const unreadDivider = document.getElementById("unread-divider")
        console.log("trying to find unread", unreadDivider);
        unreadDivider?.scrollIntoView()
      } else {
        scrollToBottom()
      }
      setInitialMessagesLoaded(true)
    }
  }, [initPageLoading])

  // Scroll user to bottom of the page once they have sent the message
  useEffect(() => {
    if (!sendingMessage)
      scrollToBottom()
  }, [sendingMessage])

  useEffect(() => {
    if (hasUnreadMessages) {
      if (isInViewport(messagesEndRef.current!)) {
        console.log("message end div in viewport");
        setHasUnreadMessages(false)
        return
      }
      if (chatbotState.isChatbotChat) {
        scrollToBottom()
      } else {
        setShowScrollIcon(true)
      }
    }
  }, [chatMessages, chatbotState.isChatbotChat, hasUnreadMessages])

  useEffect(() => {
    const lastMessageLoc = chatMessages.length - 1
    if (chatMessages[lastMessageLoc]?.senderType === MessageType.CLOSED_CONNECTION || 
        chatMessages[lastMessageLoc]?.senderType === MessageType.WELCOME 
      ) {
      console.log("This is the end of chat for the latest message");
      setIsChatActive(false)
      setChatOptionsState("notSent")
    } else {
      setIsChatActive(true)
    }
    (async () => {
      // refetching on going chat
      const onGoingChatResponse: OnGoingChat = await getOngoingChat();
      setOngoingChatId(onGoingChatResponse?.chatId || null)
      setIsChatActive(!!onGoingChatResponse?.chatId)
      // refetch chatbot enabled status
      const newUserDetails = await getUserDetails()
      setUserDetails(newUserDetails)
    })()
  }, [chatMessages])

  useEffect(() => {
    if (!hasUnreadMessages) {
      setShowScrollIcon(false)
    }
  }, [hasUnreadMessages])

  useEffect(() => {
    if (oldMessagesLoading) loadLock.current = true
    if (!oldMessagesLoading) {
      const prevFirstMessageNode = document.getElementById(prevFirstMessageID.current)
      console.log("prev first message node:", prevFirstMessageNode, prevFirstMessageID.current)
      console.log("scrolling to prev first message node...", prevFirstMessageNode, prevFirstMessageID.current)
      prevFirstMessageNode?.scrollIntoView()
      console.log("scrolled to prev first message node...", prevFirstMessageNode, prevFirstMessageID.current)
      loadLock.current = false
    }
  }, [oldMessagesLoading])

  useEffect(() => {
    console.log("oldMessagesLoading changed: ", oldMessagesLoading);
  }, [oldMessagesLoading])

  /**
   * Polling for new messages at regular interval (POLLING_INTERVAL milliseconds)
   */
  useInterval(() => {
    if (!document.hidden) loadNewChatMessages()
  }, sendingMessage ? null : POLLING_INTERVAL)

  const scrollToBottom = () => {
    setUnreadCount(0);
    window.scrollTo(0, document.body.scrollHeight)
  }

  /**
   * This function sets the prevCursorId on the last message of response and 
   * appends welcome message if required
   */
  const setPrevCursorId = (res: any, isChatbot: boolean, username?: string) => {
    const lastMessageLoc: number = res.messages.length - 1

    if (res.messages[lastMessageLoc].senderType === MessageType.CLOSED_CONNECTION) {
      const welcomeMessage = getWelcomeMessage(isChatbot, username)
      welcomeMessage.prevCursorId = res.pagination.prevCursorId
      res.messages.push(welcomeMessage)
    } else {
      res.messages[lastMessageLoc].prevCursorId = res.pagination.prevCursorId
    }
  }

  /**
   * This function adds unread message divider if required to messages.
   */
  const insertUnreadMessageDivider = (messages: any[]) => {
    let lastIndex = messages.length - 1
    // If there is no unread message, return
    if (!messages[lastIndex].isUnread) {
      return messages
    }

    // Find the index of the last read message
    let i = lastIndex
    for (; i >= 0; i--) {
      if (!messages[i].isUnread)
        break
    }

    setUnreadCount(unreadCount);
    let firstUnreadMessageIndex = i + 1
    const unreadMessageDivider: ChatMessage = {
      senderType: MessageType.UNREAD_MESSAGE_DIVIDER,
      content: {
        messageDetails: {
          message: i18n.t("helpcentre_chat_page_chat_divider_1")
        }
      },
      type:"TEXT",
      messageTime: messages[firstUnreadMessageIndex].messageTime,
    }
    unReadMessageDividerPresent.current = true

    return [...messages.slice(0, firstUnreadMessageIndex), unreadMessageDivider, ...messages.slice(firstUnreadMessageIndex)]
  }

  /**
   * This function loads the initial messages on mount
   */
  const loadInitialChatMessages = async (username?: string) => {
    const unReadMessagesFlag = {
      "unreadMsg": {
        "isUnreadMsg": true,
        "minPageSize": chatPageSize
      }
    }
    const res = await getChatMessages(unReadMessagesFlag)
    dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_CHAT, payload: res.contactStatus.isChatbot  })
    dispatchChatbotAction({ type: ChatbotActionKind.SET_SHOULD_HANDOVER_TO_AGENT, payload: res.contactStatus.agentRedirect })
    const numOfMessages = res.messages?.length || 0
    if (numOfMessages === 0) {
      setChatMessages([getWelcomeMessage(res.contactStatus.isChatbot, username), ...chatMessages])
      return
    }

    res.messages = insertUnreadMessageDivider(res.messages)
    res.messages[0].nextCursorId = res.pagination.nextCursorId
    setPrevCursorId(res, res.contactStatus.isChatbot, username)
    setChatMessages([...res.messages, ...chatMessages])
  }

  /**
   * This function loads the new messages (messages after the last message currently on the page)
   * @param isPolling bool value to check if we are polling for new messages
   */
  const loadNewChatMessages = async () => {
    const lastMessageLoc: number = chatMessages.length - 1
    const data = {
      prevCursorId: chatMessages.length > 0 ? chatMessages[lastMessageLoc].prevCursorId : "",
    }
    try {
      const res = await getMessages(data, { signal: getMessagesController.signal })
      // Cancel the previous request to get messages if it's still in progress
      getMessagesController.abort()

      // Create a new controller to abort new requests
      getMessagesController = new AbortController()

      dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_CHAT, payload: res.contactStatus.isChatbot  })
      dispatchChatbotAction({ type: ChatbotActionKind.SET_SHOULD_HANDOVER_TO_AGENT, payload: res.contactStatus.agentRedirect })

      const numOfMessages = res.messages?.length
      if (numOfMessages > 0) {
        const lastMessageLoc: number = numOfMessages - 1
        res.messages[lastMessageLoc].prevCursorId = res.pagination.prevCursorId
        if (res.messages[lastMessageLoc].senderType !== MessageType.USER) {
          setHasUnreadMessages(true)
        }
        setChatMessages([...chatMessages, ...res.messages])
        setUnreadCount((prevState: number) => prevState+ 1)
        if (chatbotState.isChatbotResponseLoading) {
          dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_RESPONSE_LOADING, payload: false })
        }
      } 
    } catch (error) {
      console.error(error)
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "get-new-chat-messages" }))
      if (chatbotState.isChatbotResponseLoading) {
        dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_RESPONSE_LOADING, payload: false })
      }
    }
  }

  const sendChatMessage = async (messageToBeSent: SendChatMessage) => {
    setSendingMessage(true)
    try {
      // throw Error
      await sendMessage(messageToBeSent)
      const getFeatureFlagsResponse = await getFeatureFlags();
      setBackendFeatureFlags(getFeatureFlagsResponse.res) 
      await loadNewChatMessages()
      setCurrMessage("")
      setSendingMessage(false)
      setChatOptionsState("sent")
      setIsChatActive(true) 
      if (chatbotState.isChatbotChat) dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_RESPONSE_LOADING, payload: true })
    } catch (error) {
      console.error(error)
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "send-chat-message" }))
      setChatOptionsState("notSent")
      const parentElement = chatOptionsRef.current?.children[0] as HTMLDivElement
      animateMessageToChatOptions(parentElement)
      setSendingMessage(false)
    } 
  }

  const handoverChatToAgent = async () => {
    dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_AGENT_HANDOVER_LOADING, payload: true }) 
    try {
      await sendMessage({
        messageType: "TEMPLATE_TEXT",
        message: i18n.t("helpcentre_chat_page_chatbot_agent_handover")
      })
      await handoverToAgent()
      dispatchChatbotAction({ type: ChatbotActionKind.SET_SHOULD_HANDOVER_TO_AGENT, payload: false })
      dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_CHATBOT_CHAT, payload: false })
      await loadNewChatMessages()
    } catch(error) {
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "handover-to-agent" }))
    } finally {
      dispatchChatbotAction({ type: ChatbotActionKind.SET_IS_AGENT_HANDOVER_LOADING, payload: false }) 
    }
  } 

  const handleChatBubbleClick = async (message: ChatMessage) => {
    console.log("clicked on message", message)
    if (message.type === "DOCUMENT") {
      if (!message.content.attachmentDetails?.documentID) return
      const fileContent = await getDoc(message.content.attachmentDetails?.documentID)
      const fileType = checkDocumentType(message.content.attachmentDetails?.fileName, chatDocumentUploadConfig.allowedDocTypes)
      navigator(routes.fileViewer.path, {
        state: {
          content: fileContent,
          type: fileType
        }
      })
    }
  }

  const isInViewport = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const updateCurrMessage = (event: any) => {
    setCurrMessage(event.target.value)
  }

  const formatMessageTime = (message: ChatMessage) => {
    if (message.senderType === MessageType.WELCOME) {
      return ""
    }

    let date = new Date(message.messageTime)
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: TIME_ZONE })
  }

  const displayChatMessage = (message: ChatMessage, itemIndex: number, index: number) => {
    const firstMessage = (itemIndex === 0 && index === 0)
    const uniqueKey = `message-${getChatMessageContent(message)}-${message.messageTime}`
    if (dividerMessageTypes.includes(message.senderType)) {
      return <ChatDivider text={getChatMessageContent(message)} messageRef={firstMessage ? firstMessageRef : null} id={message.senderType === MessageType.UNREAD_MESSAGE_DIVIDER ? "unread-divider" : ""} key={uniqueKey} />
    }
    return (
      <Chatbubble {...getChatBubbleProps(message)} onClick={() => handleChatBubbleClick(message)} messageRef={firstMessage ? firstMessageRef : null} id={uniqueKey} />
    )
  }

  const getWelcomeMessage = (isChatbot: boolean, username?: string): ChatMessage => {
    return {
      senderType: MessageType.WELCOME,
      content: {
        messageDetails: {
          message: isChatbot ? i18n.t("helpcentre_chat_page_welcome_message_with_chatbot", {
            prefferedName: username
          }) : i18n.t("helpcentre_chat_page_welcome_message", {
            preferredName: username
          })
        }
      },
      type: "TEXT",
      messageTime: new Date().toISOString(),
    }
  }

  const postFeedbackFn = async (messageId: string) => {
    dispatchChatbotAction({ type: ChatbotActionKind.ADD_SUBMITTED_FEEDBACK_MESSAGES, payload: messageId  })
    await loadNewChatMessages()
  }

  const getChatBubbleProps = (message: ChatMessage): ChatBubbleProps & { 'key'?: string } => {
    return {
      messageId: message.messageId,
      style: { alignSelf: getElementAlignment(message.senderType.toLowerCase()) },
      type: message.type,
      content: getChatMessageContent(message),
      feedbackState: message.messageId && chatbotState.feedbackSubmittedMessages.has(message.messageId) ? FeedbackState.SUBMITTED : 
        message.isFeedbackEnabled ? FeedbackState.ENABLED : FeedbackState.DISABLED,
      postFeedbackFn: postFeedbackFn,
      sender: message.senderType === MessageType.AGENT ? message.senderName : null,
      time: formatMessageTime(message),
      variant: message.senderType.toLowerCase() as ChatBubbleProps["variant"],
      key: `message-${getChatMessageContent(message)}-${message.messageTime}`,
    }
  }

  const getChatMessageContent = (message: ChatMessage) => {
    if (message.type === "DOCUMENT") {
      return message.content.attachmentDetails?.fileName
    }
    return message.content.messageDetails?.message || ""
  }

  const getElementAlignment = (variant: string) => {
    if (variant === "user") {
      return "flex-end"
    } else if (variant === "agent" || variant === "welcome") {
      return "flex-start"
    } else return "center"
  }

  const isInputValid = () => {
    return currMessage.trim().length > 0
  }

  /**
   * This function groups all messages by dates
   */
  const setMessages = (messages: ChatMessage[]) => {
    let dateSeperatedMessages: any = {}
    for (const message of messages) {
      let date = new Date(message.messageTime).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', timeZone: TIME_ZONE }).replace(',', '')
      if (!dateSeperatedMessages[date]) {
        dateSeperatedMessages[date] = []
      }
      dateSeperatedMessages[date].push(message)
    }
    chatData = dateSeperatedMessages

  }

  setMessages(chatMessages)

  // Resizing issue on input field focus on android
  let currentHeight = window.innerHeight
  window.onresize = () => {
    let scrollDistance = currentHeight - window.innerHeight
    currentHeight = window.innerHeight
    if (scrollDistance < 0) {
      return
    }
    window.scrollBy(0, scrollDistance)
  }

  const handleFileChange = async (ev: any) => {
    if (!ev.target.files) return
    console.log(ev.target.files);
    const file = ev.target.files[0]
    const splitFileName = file.name.split('.')
    const extensionLocation = splitFileName.length - 1
    const fileExtension = splitFileName[extensionLocation]
    const fileObj: fileData = {
      fileName: file.name,
      fileContent: (await readURL(file) as string).split(',')[1],
      fileExtension,
      fileType: "inAppChat",
    }
    if (typeof setUploadFile === "function") {
      // console.log(setUploadFile, "calling setUploadFile");
      setUploadFile(fileObj)
    }
    setFile(fileObj)
    ev.target.value = ""
  }

  useEffect(() => {
    if (fileStatus === "SUCCESS") {
      console.log(fileUplodInfo, "fileuploadinfo right now before sending message")
      sendChatMessage({
        messageType: "DOCUMENT",
        message: fileUplodInfo.id,
        fileName: fileUplodInfo.name
      })
    }
  }, [fileStatus])

  const leaveChatroom = useMemo(() => {
    return <Button
        variant="contained"
        onClick={async () => {
          setLeaveChatpageLoading(true)
          try {
            await closeChatbotCase(ongoingChatId ?? '')
            setLeaveChatpageLoading(false)
            navigation.confirm()
          } catch(error) {
            setLeaveChatpageLoading(false)
            openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "leave-chat-room" })) 
          }
        }}
        sx={{
            backgroundColor: "brand-primary.main",
            '&:hover': {
                backgroundColor: 'brand-primary.main'
            },
            flex: 1
        }}
    >
        <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
           {i18n.t('generic_exit')}
        </Typography>
    </Button>
}, [navigation])

const returnToChat = useMemo(() => {
    return <Button
        variant="outlined"
        onClick={() => navigation.cancel()}
        sx={{
            borderColor: "#FAFAFA",
            backgroundColor: "bg-secondary.main",
            '&:hover': {
                borderColor: "#FAFAFA",
                backgroundColor: "bg-secondary.main"
            },
            flex: 1
        }}
    >
        <Typography variant="callout-02" p={5} sx={{ color: 'text-primary.main' }}>
          {i18n.t('generic_go_back')} 
        </Typography>
    </Button>
}, [navigation])

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      pb: (isChatActive || chatbotState.isChatbotChat || !featureFlags.skillBasedRouting) ? 9 : 0
    }}>
      {
        (initPageLoading || oldMessagesLoading) &&
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 5 }}>
          <DottedLoader />
        </Box>
      }
      {
        Object.keys(chatData).map((key: string, itemIndex: number) => {
          const dateKey = key
          const chatDataMessages = chatData[dateKey]
          return (
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative"
            }}
              key={'item' + itemIndex}
            >
              <Chatbubble
                style={{
                  alignSelf: "center",
                  position: "sticky",
                  top: "70px"
                }}
                content={key}
                variant="info"
              ></Chatbubble>
              {chatDataMessages.map((message: ChatMessage, index: number) => displayChatMessage(message, itemIndex, index))}
            </Box>
          )
        })
      }
      {chatbotState.isChatbotResponseLoading &&
        <Box sx={{
          display: "flex",
        }}> 
          <Chatbubble
            style={{
            alignSelf: "flex-start",
            }}
            content={<ChatBubbleLoader />}
            variant="agent"
          ></Chatbubble>
        </Box>
      }

      {
        (chatMessages.length && !isChatActive && featureFlags.skillBasedRouting && !currMessage) &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/** Replace after phrase key update */}
          {chatbotState.isChatbotChat && userDetails?.chatbotEnabled && <ChatDivider sx={{ mt: 0 }} text={i18n.t("helpcentre_chat_page_chat_divider_3")} />}
          <Box
            ref={chatOptionsRef}
            sx={{
              alignSelf: "flex-end",
              width: "100%",
              marginLeft: "auto",
              transition: "all ease-in-out 500ms"
            }}
          >  
            <CurvedListItemContainer
              sx={{
                ml: "auto"
              }}>
              {(chatOptionsState === "sending" || chatOptionsState === "sent") ? <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  ml: "auto",
                  display: "flex",
                  placeItems: "center",
                  placeContent: "center",
                  backgroundColor: theme.palette["brand-primary"].main,
                  color: theme.palette["text-primary"].main
                }}
              >
                <CircularProgress color="inherit" size={24} />
              </Box> : <>
                {chatIssueTypes.map((option, index) => {
                  return (
                    <ListItem
                      onClick={() => {
                        setChatOptionsState("sending")
                        const parentElement = chatOptionsRef.current?.children[0] as HTMLDivElement
                        console.log("needs to go from ", parentElement, parentElement?.getBoundingClientRect());
                        animateChatOptionsToMessage(parentElement)
                        sendChatMessage({
                          messageType: "TEXT",
                          message: i18n.t(option)
                        } as SendChatMessage)
                        sendScribeEvent("help_chat_submit")
                      }}
                      key={`${option}-${index}`}
                      primaryText={
                        <Typography
                          variant="body-02"
                          sx={{
                            color: "text-primary.main",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                          className="line-clamp"
                        >
                          {i18n.t(option)}
                        </Typography>
                      }
                      secondaryIcon={
                        <IconButton><img src={ChevronRight} alt="" /></IconButton>
                      }
                      isCurved={false}
                    />
                  )
                })
                }</>
              }
            </CurvedListItemContainer>
          </Box>
          {!chatbotState.isChatbotChat && !userDetails?.chatbotEnabled && <ChatDivider text={i18n.t("helpcentre_chat_page_chat_divider_2")} />} 
        </Box>
      }
      <Box sx={{
        height: (fileStatus === "UPLOADING" || fileStatus === "SCANNING") ? "110px" : (chatbotState.shouldHandoverToAgent && !chatbotState.isChatbotResponseLoading) ? "35px"  : "10px",
        width: "100%"
      }} ref={messagesEndRef} /> 
      {
        showScrollIcon &&
        <IconButton
          sx={{
            backgroundColor: 'text-primary.main',
            alignSelf: "flex-end",
            position: "sticky",
            width: "56px",
            height: "56px",
            bottom: "100px",
            '&:hover': { backgroundColor: 'text-primary.main' },
          }}
          onClick={() => scrollToBottom()}
        >
          <img src={ChevronDown} alt="" />
          <Badge 
            badgeContent={unreadCount} 
            color="error" 
            max={9} 
            sx={{
              position: "absolute",
              top: 4,
              right: 10
            }} 
          />
        </IconButton>
      }
      { featureFlags.chatDocUpload && 
        (fileStatus === "UPLOADING" || fileStatus === "SCANNING") &&
        <Box key={'fileKey-'+file.fileName}
          sx={{
            position: "fixed",
            bottom: "70px",
            backgroundColor: "bg-secondary.main",
            left: "0",
            right: "0",
            p: 5,
            pb: 7,
            border: "none",
            borderRadius: 3,
            borderTop: `1px solid ${theme.palette["bg-secondary"].main}`
          }}>
          <FileProgress fileStatus={fileStatus}></FileProgress> 
        </Box>
      }
      {
        (isChatActive || chatbotState.isChatbotChat || !featureFlags.skillBasedRouting) && (initialMessagesLoaded) && 
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            left: "0",
            right: "0",
            display: "flex",
            flexDirection: "column",
            zIndex: 1,
          }} 
        >
        {
          chatbotState.shouldHandoverToAgent && !chatbotState.isChatbotResponseLoading &&
          <Box
            sx={{
              py: 3,
              px: 6,
              backgroundColor: "bg-primary.main",
            }}
          >
            <Button
              variant="contained"
              onClick={handoverChatToAgent}
              sx={{
                backgroundColor: 'bg-tertiary.main' ,
                '&:hover': {
                  backgroundColor: 'bg-tertiary.main',
                },
                minWidth: '48vw',
                '&:Mui-disabled': {
                  opacity: 0.8,
                },
              }}
            >
              <Typography variant="callout-03">
                { chatbotState.isAgentHandoverLoading ? <CircularProgress color="inherit" size={15} /> : i18n.t("helpcentre_chat_page_chatbot_agent_handover") }
              </Typography>
            </Button>
          </Box>
        }
          <Box className="animate-input"
            sx={{
              backgroundColor: "bg-primary.main",
              display: "flex",
              justifyContent: "space-between",
              py: 5,
              px: 6,
              border: "none",
              borderTop: `1px solid ${theme.palette["bg-secondary"].main}`
            }}>
            {
              featureFlags.chatDocUpload && 
              <Box
                sx={{
                  mr: 5,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <input hidden type="file"
                  // On change, add chat bubble with type upload, update status and actions with useUpload hook that nees to be generated
                  accept={getAllDoctypesToString(chatDocumentUploadConfig.allowedDocTypes)}
                  onClick={() => {
                    console.log("clicked the upload button");
                    checkPermissions()
                  }}
                  disabled={chatbotState.isChatbotChat}
                  onChange={handleFileChange}
                  name="chatDocUpload"
                  id="chatDocUpload"
                />
                {
                  (fileStatus === "UPLOADING" || fileStatus === "SCANNING") ? 
                  ""
                    : <label htmlFor="chatDocUpload">
                      <img style={{
                        height: "20px",
                        opacity: chatbotState.isChatbotChat ? 0.2 : 1
                      }} src={CrossSmall}
                      alt=""></img>
                    </label>
                }
              </Box>
            }
            <ChatInput disabled={
              (!isChatActive && !chatbotState.isChatbotChat && featureFlags.skillBasedRouting) ||
              (chatbotState.isChatbotChat && !userDetails?.chatbotEnabled)
              } 
              sx={{ width: "100%" }} placeholder={i18n.t("helpcentre_chat_page_type_anything")} value={currMessage} updateInputText={updateCurrMessage} />
            {
              sendingMessage && chatOptionsState !== "sending" ?
                <Box sx={{ display: "flex", color: "white", alignItems: "center", ml: 5 }}>
                  <CircularProgress color="inherit" />
                </Box> :
                <Button 
                  onClick={() => sendChatMessage({
                    messageType: "TEXT",
                    message: currMessage.trim()
                  } as SendChatMessage)}
                  sx={{ ml: 5, p: 0, minWidth: 0, display: isInputValid() ? "inline-flex" : "none" }}
                  disabled={chatbotState.isChatbotResponseLoading || chatbotState.isAgentHandoverLoading}
                >
                  <img style={{ width: "25px", opacity: chatbotState.isChatbotResponseLoading || chatbotState.isAgentHandoverLoading ? 0.2 : 1 }} src={sendIcon} alt="" />
                </Button>
            }
          </Box>
        </Box>
      }
      <ActionSheet
        id="close-chatbot-chat-bottomsheet"
        icon={<img src={Hazard} alt="" />}
        open={showPrompt}
        onClose={() => navigation.cancel()}
        title={i18n.t("helpcentre_chat_page_leave_chat_title")}
        description={i18n.t("helpcentre_chat_page_leave_chat_body")}
        primaryButton={leaveChatroom}
        secondaryButton={returnToChat}
        buttonGroupDirection="column-reverse"
      />

      {
        leaveChatpageLoading &&
        <Box
          sx={{
            zIndex: "3000",
          }}
        >
          <Box
            sx={{
              opacity: "0.75",
              height: "100vh",
              width: "100%",
              position: "fixed",
              top: "0",
              left: "0",
              background: "black"
            }}>
          </Box>
          <DottedLoaderBox
            sx={{
              position: "fixed",
              m: 'auto',
              top: "50%",
              left: '50%',
              transform: "translate(-50%, -50%)"
            }}
          />
        </Box>
      }
    </Box>
  );
};

export default ChatPage;