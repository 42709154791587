import "./FilePicker.scss";
import PropTypes, { InferProps } from "prop-types";
import CrossSmall from '../../assets/cross-small.svg';
import TrashSmall from '../../assets/trash-small.svg';
import { Typography, IconButton, Box, Button } from "@mui/material";
import ListItem from "../ListItem/ListItem";
import CurvedListItemContainer from "../CurvedListItemContainer/CurvedListItemContainer";
import BottomSheet from "../BottomSheet/BottomSheet";
import { bytesToMB, getSnackbarArgs } from "../../utils/utils";
import { checkPermissions } from "../../utils/mobileComms";
import routes from "../../utils/routes";
import useSnackbars from "../../context/Snackbar/Consumer";
import { Link } from "react-router-dom";
import { useState } from "react";
import { readURL } from "../../utils/dynamicForm";
import { checkDocumentType, getAllDoctypesToString } from "../../utils/filePicker";
import i18n from '../../i18n/config';

const FilePickerPropTypes = {
  title: PropTypes.string,
  maxFileSizeInMB: PropTypes.number.isRequired,
  totalFileSizeInMB: PropTypes.number.isRequired,
  maxDocuments: PropTypes.number.isRequired,
  allowedDocTypes: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.string.isRequired
    ).isRequired
  ).isRequired,
  onDocumentView: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
}

let deleteIndex: number = -1

const FilePicker = (props: InferProps<typeof FilePickerPropTypes>) => {

  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  const { openSnackbar, closeSnackbar } = useSnackbars();

  const closeBottomSheet = () => {
    setOpenBottomSheet(false)
  }

  const removeDocument = (index: number) => {
    if (index < 0 || (!index && index !== 0)) {
      return
    }
    const newFiles = props.files
    newFiles.splice(index, 1)
    console.log(newFiles, props.files);
    props.setFiles([...newFiles])
  }

  // TODO: Break this function down
  const checkDocuments = async (event: any) => {
    const filesRecieved = event.target.files
    if (filesRecieved?.length > props.maxDocuments) {
      console.log("error, files more than 5");
      openSnackbar(getSnackbarArgs({ onClose: closeSnackbar, uniqueKey: "files-quantity-exceeded-error" }))
      return
    }
    const filesArray = []
    for (const file of filesRecieved) {
      if (Number(bytesToMB(file.size)) > props.maxFileSizeInMB) {
        console.log(`error, file size greater than ${props.maxFileSizeInMB}MB`);
        openSnackbar(getSnackbarArgs({ secondaryMessage: `The document was larger than ${props.maxFileSizeInMB}MB. Try attaching a smaller document.`, onClose: closeSnackbar, uniqueKey: "files-size-exceeded-error" }))
        return
      }
      let fileContent: string = await readURL(file) as string

      const currentTotal = (props.files?.length ? bytesToMB((props.files.reduce((prev, current) => {
        console.log(prev, current);
        return prev + current.size
      }, 0) + file.size))
        :
        bytesToMB(file.size)
      )

      if (Number((currentTotal).toFixed(1)) > props.totalFileSizeInMB) {
        openSnackbar(getSnackbarArgs({ secondaryMessage: `All your files add up to more than ${props.totalFileSizeInMB}MB. Remove a document or attach a smaller one.`, onClose: closeSnackbar, uniqueKey: "files-size-exceeded-error" }))
        return
      }
      filesArray.push({
        fileName: file.name,
        content: fileContent,
        size: file.size
      })
    }
    props.setFiles([...props.files, ...filesArray])
    event.target.value = ""
    console.log(props.files.toString(), filesArray);
  }

  return (
    <>
      <CurvedListItemContainer
        sx={{
          mb: 6
        }}
      >
        <>
        <input type="file" accept={getAllDoctypesToString(props.allowedDocTypes)} id="fileUp" disabled={props.files?.length >= props.maxDocuments} name="fileUp" hidden onChange={checkDocuments} onClick={checkPermissions} />
        <label htmlFor="fileUp">
          <ListItem
              sx={{
                borderBottomLeftRadius: "0 !important",
                borderBottomRightRadius: "0 !important"
              }}
              primaryText={
                <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{props.title}</Typography>
              }
              tertiaryText={
                <Typography variant="metadata-01" sx={{ color: "text-tertiary.main" }}>
                  <>
                    {i18n.t("generic_file_limit_part_1")}{props.maxFileSizeInMB}{i18n.t("generic_file_limit_part_2")}{props.maxDocuments}{i18n.t("generic_file_limit_part_3")}<br />
                    {i18n.t("generic_file_format")}{getAllDoctypesToString(props.allowedDocTypes)}
                  </>
                </Typography>
              }
              secondaryIcon={
                <img src={CrossSmall} alt="" />
              }
          />
        </label>
          {
            props.files.map((file, index: number) => {
              const fileType = checkDocumentType(file.fileName, props.allowedDocTypes)
              return (
                <ListItem
                  key={index}
                  sx={{ wordBreak: "break-word" }}
                  isCurved={false}
                  primaryText={
                    <Typography variant="body-02" sx={{ color: "text-primary.main" }}>{file.fileName}</Typography>
                  }
                  secondaryText={
                    <Typography variant="metadata-01" sx={{ color: "text-tertiary.main", my: 2 }}>{bytesToMB(file.size).toFixed(1)} MB</Typography>
                  }

                  tertiaryText={
                    <Link
                      to={routes.fileViewer.path}
                      state={
                        {
                          content: file.content,
                          type: fileType
                        }}
                      className="no-text-decoration"
                      onClick={props.onDocumentView}
                    >
                      <Typography variant="callout-03" sx={{ color: "text-link.main" }}>{i18n.t("generic_preview")}</Typography>
                    </Link>
                  }

                  secondaryIcon={
                    <IconButton onClick={() => {
                      deleteIndex = index
                      setOpenBottomSheet(true)
                    }
                    }>
                      <img src={TrashSmall} alt="" />
                    </IconButton>
                  } />
              )
            })
          }
        </>
      </CurvedListItemContainer>

      <BottomSheet
        open={openBottomSheet} onClose={closeBottomSheet}>
        <Box p={6} sx={{ backgroundColor: "bg-secondary.main" }}>
          <Typography sx={{ wordBreak: "break-word", mb:7 }} variant="callout-01">{i18n.t("generic_remove_confirmation_title")}</Typography>
          <br />
          <Typography variant="body-02" sx={{mt: 6}} >{i18n.t("generic_remove_confirmation_body_2")}</Typography>
          <Button
            variant="contained"
            onClick={() => {
              console.log(deleteIndex, props.files[deleteIndex].fileName, "should be correct here");
              removeDocument(deleteIndex)
              deleteIndex = -1
            }
            }
            color="btn-primary"
            sx={{
              width: '100%',
              mt: 6
            }}
          >
            <Typography variant="callout-02" p={5}>
              {i18n.t("generic_delete")}
            </Typography>
          </Button>
          <Button
            variant="text"
            onClick={closeBottomSheet}
            fullWidth
          >
            <Typography variant="callout-02" p={5}>
              {i18n.t("generic_go_back")}
            </Typography>
          </Button>
        </Box>
      </BottomSheet>
    </>
  );
};

FilePicker.propTypes = FilePickerPropTypes;

FilePicker.defaultProps = {
  title: `{i18n.t("generic_upload_document_optional")}`
}

export default FilePicker;
